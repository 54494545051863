<template>
  <vca-row>
    <div>
      <h3>{{ $t("network.crews.list.organisation_filter.header") }}</h3>
      <div>
        <vca-checkbox
          v-for="org in organisationList"
          v-model="value.organisations"
          :key="org.id"
          :id="org.id"
          >{{ org.name }}</vca-checkbox
        >
      </div>
    </div>
    <div>
      <h3>{{ $t("network.crews.list.entity_filter.header") }}</h3>
      <div>
        <vca-checkbox
          v-for="entity in entities"
          v-model="value.entities"
          :key="entity"
          :id="entity"
          >{{ $t("network.crews.list.entity_filter." + entity) }}</vca-checkbox
        >
      </div>
    </div>
  </vca-row>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "CrewFilter",
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  created() {
    this.$store.dispatch("organisations/list");
  },
  computed: {
    ...mapGetters({
      entities: "crews/entities",
      organisationList: "organisations/list",
    }),
  },
};
</script>
